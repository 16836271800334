<template>
  <v-container class="pt-0 px-0">
    <back-toolbar :title="$t('menu.privacyterms')"></back-toolbar>
    <v-card flat>
      <v-card-text v-html="$t('dds.privacyTerms')" class="v-html" />
    </v-card>
  </v-container>
</template>

<script>
import BackToolbar from "@/components/app/common/BackToolbar";
export default {
  components: {
    BackToolbar,
  },
};
</script>
